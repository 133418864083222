import React from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './Home';
import Auth from './Auth';
import EmailChange from './EmailChange';
import PasswordReset from './PasswordReset';
import AuthChange from './AuthChange';
import TermsNConditions from './TermsNConditions';
import PrivacyNPolicy from './PrivacyNPolicy';
import Navbar from './Navbar';
import Support from './Support';

function App() {
  return (
    <div className='Main'>
      {/* <Navbar/> */}
      <Routes>
        <Route exact path='/' element={<Home />} />
        <Route exact path='/terms&conditions' element={<TermsNConditions />} />
        <Route exact path='/PrivacyPolicy' element={<PrivacyNPolicy />} />
        <Route exact path='/activate/*' element={<Auth />} />
        <Route
          exact
          path='/reset_email/*'
          element={<AuthChange focus='email' />}
        />
        <Route
          exact
          path='/reset-password/*'
          element={<AuthChange focus='password' />}
        />
      </Routes>
    </div>
  );
}

export default App;
