import React, { useState, useEffect } from 'react';
import axios from 'axios';

function AuthChange({ focus }) {
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(-1);
  const [email, setEmail] = useState(['', '']);
  const [password, setPassword] = useState(['', '']);

  useEffect(() => {
    setLoading(-1);
  }, []);
  const handlePassword = (e, index) => {
    setPassword((prev) =>
      index ? [prev[0], e.target.value] : [e.target.value, prev[1]]
    );
  };

  const handlePasswordSubmit = () => {
    const uid = window.location.pathname.split('/')[2];
    const token = window.location.pathname.split('/')[3];

    setLoading(0);
    if (uid && token) {
      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_PASSWORD_RESET_URL}`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          uid,
          token,
          new_password: password[0],
          re_new_password: password[1],
        },
      };
      console.log(
        `${process.env.REACT_APP_PASSWORD_RESET_URL}`,
        '${process.env.REACT_APP_PASSWORD_RESET_URL}',
        config
      );
      axios
        .request(config)
        .then((response) => {
          console.log(`email reset got approved-------->`, response);
          setMessage('Email reset got approved');
          setLoading(1);
        })
        .catch((error) => {
          console.log(error, `email reset did not get approved------------->`);
          setMessage(error.message);
          setLoading(-1);
        });
    }
  };
  const validateEmail = (e) => {
    const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    return e.match(isValidEmail);
  };

  const handleEmail = (e, index) => {
    setEmail((prev) =>
      index ? [prev[0], e.target.value] : [e.target.value, prev[1]]
    );
  };

  const handleEmailSubmit = () => {
    if (validateEmail(email[0]) && validateEmail(email[1])) {
      const uid = window.location.pathname.split('/')[2];
      const token = window.location.pathname.split('/')[3];
      setLoading(1);
      if (uid && token) {
        let config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: `${process.env.REACT_APP_EMAIL_RESET_URL}`,
          headers: {
            'Content-Type': 'application/json',
          },
          data: {
            uid,
            token,
            email: email[0],
            new_email: email[1],
          },
        };

        axios
          .request(config)
          .then((response) => {
            console.log(`email reset got approved-------->`, response);
            setMessage('Email reset got approved');
            setLoading(0);
          })
          .catch((error) => {
            console.log(
              error,
              `email reset did not get approved------------->`
            );
            setMessage(error.message);
            setLoading(-1);
          });
      }
    } else {
      setMessage(
        'There was an issue with this email address, Please try again...'
      );
      setLoading(0);
    }
  };

  return (
    <div className='authChangeWrapper'>
      <div className='homeWrapper'>
        <div className='homeWrapper-description-background'>
          <span />
        </div>
        <div
          className='homeWrapper-rightSideArt'
          id='homeWrapper-rightSideArt-approved'
        ></div>
        <div className='homeWrapper-name'>
          <span>MyReflection. ai</span>
        </div>
        <div className='homeWrapper-slogen' />
        <div
          className='homeWrapper-description'
          id='homeWrapper-description-approved'
        >
          {loading === 0 ? (
            <span className='beat-loader'>
              <div className='dot'></div>
              <div className='dot'></div>
              <div className='dot'></div>
              <div className='dot'></div>
              <div className='dot'></div>
            </span>
          ) : (
            <span>{loading === 1 ? message : ''}</span>
          )}
        </div>
      </div>
      {loading === -1 ? (
        <div id='authChange-layer'>
          {focus === 'email' ? (
            <>
              <input
                className='authChangeWrapper-input old'
                type='email'
                value={email[0]}
                onChange={(e) => handleEmail(e, 0)}
                placeholder='Enter old Email...'
                required
              />
              <input
                className='authChangeWrapper-input new'
                type='email'
                value={email[1]}
                onChange={(e) => handleEmail(e, 1)}
                placeholder='Enter New Email...'
                required
              />
            </>
          ) : (
            <>
              <input
                className='authChangeWrapper-input old'
                type='password'
                value={password[0]}
                onChange={(e) => handlePassword(e, 0)}
                placeholder='Enter New Password...'
                required
              />
              <input
                className='authChangeWrapper-input new'
                type='password'
                value={password[1]}
                onChange={(e) => handlePassword(e, 1)}
                placeholder='Re Enter New Password...'
                required
              />
            </>
          )}
          <div
            className='authChange-submit-btn-wrapper'
            onClick={
              focus === 'email' ? handleEmailSubmit : handlePasswordSubmit
            }
          >
            <div className='authChange-submit-btn'>Submit</div>
          </div>
          {message !== '' ? (
            <div className='authChange-err-msg-wrapper'>{message}</div>
          ) : null}
        </div>
      ) : null}
      {/* loading === 1 ? ( 
      <div className='authChange-msg-wrapper'>{message}</div>
       ) : null} */}
    </div>
  );
}

export default AuthChange;
