import React, { useRef, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

function Home() {
  return (
    <div className='homeWrapper' onClick={(e) => console.log(e.target)}>
      <div className='homeWrapper-description-background'>
        <span />
      </div>
      <div className='homeWrapper-rightSideArt'>
        <div className='homeWrapper-rightSideArt-img'></div>
      </div>
      <Link to='/' className='homeWrapper-name'>
        <span>MyReflection. ai</span>
      </Link>
      <div className='homeWrapper-slogen'>
        <div />
        <span>
          Create your interactive digital twin and transform your memories
        </span>
      </div>
      <div className='homeWrapper-description'>
        <span>
          Myreflection.ai aims to immortalize personal legacies by creating
          digital clones that learn from a user's life patterns, enabling
          continuous and meaningful interactions with loved ones beyond one's
          lifetime.
        </span>
        {/* <Link to='/terms&conditions' className='terms'>
          Terms and Conditions
        </Link>
        <Link to='/PrivacyPolicy' className='privacy'>
          Privacy Policy
        </Link>
        <a href='mailto:support@myreflection.ai' className='privacy'>
          Support
        </a> */}
      </div>
      <div className='homeWrapper-input-wrapper-btn'>
        <a href='https://apps.apple.com/app/6503172040'>Get Started</a>
      </div>
      <div className='homeWrapper-powered'>
        <small>Powered by CogniCore LLC</small>
        <Link to='/terms&conditions' className='terms'>
          Terms and Conditions
        </Link>
        <Link to='/PrivacyPolicy' className='privacy'>
          Privacy Policy
        </Link>
        <a href='mailto:support@myreflection.ai' className='privacy'>
          Support
        </a>
      </div>
    </div>
  );
}

export default Home;
